<template>
	<ion-page>
		<ion-content>
			<layout-novios>
				<div class="p-2 px-1 h-100 w-100">
					<div class="w-100 text-center mb-1">
						<p>{{ dataUser.name }}</p><!-- <ion-button id="open-modal" expand="block">Open Modal</ion-button> -->
						<p v-if="!dataUser.id_list" class="h5 text-warning">No tienes una lista</p>
						<p v-if="dataUser.id_list && !dataUser.lista || dataUser.lista && lengthItem(dataUser.lista) == 0" class="h5 text-warning">No has agregado ningún invitado</p>
					</div>

					<!-- Lista de invitados -->
					<ion-list v-if="dataUser.id_list && dataUser.lista && lengthItem(dataUser.lista) > 0" class="max-w-1000 h-90 pb-1 overflow-auto mx-auto">
						<ion-item v-for="(item, index) in dataUser.lista" :key="index">
							<!-- Mostramos el nombre y los cupos -->
							<ion-label><span class="mr-0-5">{{ index + 1 }})</span>{{ item.Nombre }}<span class="mx-0-5">-</span>{{ item.Adultos + item.Jovenes + item.Ninos }}</ion-label>
							<!-- Mostramos el icono de editar -->
							<ion-icon :icon="create" slot="end" color="info" @click="editInvi(index)"></ion-icon>
							<!-- Mostramos el icono de borrar -->
							<ion-icon :icon="trash" slot="end" color="danger" class="mx-1" @click="showAlert(index)"></ion-icon>
							<!-- Mostramos el icono de compartir -->
							<!-- <ion-icon v-if="dataUser.urlInvi && !item.Tel" :icon="shareSocial" slot="end" @click="shareData(item.Codigo)"></ion-icon> -->
							<ShareNetwork v-if="dataUser.urlInvi && !item.Tel" 
								network="WhatsApp"
								:url="`${this.dataUser.urlInvi}?i=${item.Codigo}`"
								title=""
								description=""  slot="end">
								<ion-icon :icon="shareSocial"></ion-icon>
							</ShareNetwork>

							<ion-icon v-if="dataUser.urlInvi && item.Tel" color="primary" :icon="logoWhatsapp" slot="end" @click="toWhatsapp(item.Tel, item.Codigo)"></ion-icon>
						</ion-item>
					</ion-list>
					<!-- Fin Lista de invitados -->


					<ion-modal ref="modal" trigger="open-modal" :enter-animation="enterAnimation" :leave-animation="leaveAnimation">
						<ion-content class="p-1">
							<ion-toolbar class="px-1">
								<ion-buttons slot="start">
									<ion-button color="danger" @click="dismiss(1)">Cancelar</ion-button>
								</ion-buttons>
								<!-- Boton crear nuevo invitado -->
								<ion-buttons slot="end">
									<ion-button color="primary" @click="addInvi()">Guardar</ion-button>
								</ion-buttons>
								<!-- Fin Boton crear nuevo invitado -->
								<!-- Boton editar invitado -->
								<!-- <ion-buttons slot="end" v-else>
									<ion-button color="primary" @click="dismiss()">Guardar</ion-button>
								</ion-buttons> -->
								<!-- Fin Boton editar invitado -->
							</ion-toolbar>
							<ion-list class="p-1">
								<ion-title v-if="inviAdd.id" class="text-center">Editar</ion-title>
								<ion-title v-else class="text-center">Nuevo Invitado</ion-title>
								<ion-item>
									<ion-label position="floating">Titulo</ion-label>
									<ion-select v-model="inviAdd.Titulo" class="mb-1">
										<ion-select-option value="apreciado">Apreciado</ion-select-option>
										<ion-select-option value="apreciados">Apreciados</ion-select-option>
										<ion-select-option value="apreciada">Apreciada</ion-select-option>
										<ion-select-option value="apreciadas">Apreciadas</ion-select-option>
										<ion-select-option value="querido">Querido</ion-select-option>
										<ion-select-option value="queridos">Queridos</ion-select-option>
										<ion-select-option value="querida">Querida</ion-select-option>
										<ion-select-option value="queridas">Queridas</ion-select-option>
									</ion-select>
								</ion-item>
								<ion-item>
									<ion-label position="floating">Nombres</ion-label>
									<ion-input v-model="inviAdd.Nombre" type="text" class="mb-1 text-trans-c"></ion-input>
								</ion-item>
								<ion-item>
									<ion-label position="floating">Cupos adultos</ion-label>
									<ion-input v-model="inviAdd.Adultos" type="number" class="mb-1" min="0"></ion-input>
								</ion-item>
								<ion-item>
									<ion-label position="floating">Cupos jóvenes</ion-label>
									<ion-input v-model="inviAdd.Jovenes" type="number" class="mb-1" min="0"></ion-input>
								</ion-item>
								<ion-item>
									<ion-label position="floating">Cupos niños</ion-label>
									<ion-input v-model="inviAdd.Ninos" type="number" class="mb-1" min="0"></ion-input>
								</ion-item>
								<ion-item>
									<ion-label position="floating">Celular</ion-label>
									<ion-input v-model="inviAdd.Tel" type="tel" class="mb-1"></ion-input>
								</ion-item>
								<ion-item>
									<ion-label position="floating">Invitado de</ion-label>
									<ion-select v-model="inviAdd.Invide" class="mb-1">
										<ion-select-option value="novio">El Novio</ion-select-option>
										<ion-select-option value="novia">La Novia</ion-select-option>
										<ion-select-option value="los dos">Los Dos</ion-select-option>
									</ion-select>
								</ion-item>
								<p class="text-center p-1 h5">Recuerda incluir el N° de celular para poder enviar el link.<br>Con su código de país, Ejemplo: <span class="text-info h5">57</span>301<span>xxxxxxx</span></p>
							</ion-list>
						</ion-content>
					</ion-modal>


					<!-- Boton para crear lista nueva -->
					<ion-fab v-if="!dataUser.id_list" vertical="bottom" horizontal="end" slot="fixed">
						<ion-button expand="block" color="medium" @click="createList()">
							Crear Lista
						</ion-button>
					</ion-fab>
					<!-- Fin Boton para crear lista nueva -->
					<!-- Boton para agregr invitado -->
					<div id="open-modal">
						<ion-fab v-if="dataUser.id_list" vertical="bottom" horizontal="end" slot="fixed">
							<ion-fab-button color="primary" expand="block">
								<ion-icon :icon="add"></ion-icon>
							</ion-fab-button>
						</ion-fab>
					</div>
					<!-- Fin Boton para agregr invitado -->
				</div>
			</layout-novios>
		</ion-content>
	</ion-page>
</template>

<script>
	const URL = "https://backend-noviasdiez.up.railway.app/api"

	import axios from 'axios'
	import { IonPage, IonContent, IonIcon, IonFabButton, IonFab, IonList, IonItem, IonLabel, toastController, alertController, createAnimation, IonTitle, IonButtons, IonButton, IonToolbar, IonModal, IonSelect, IonSelectOption, IonInput  } from '@ionic/vue'
	import { add, create, trash, shareSocial, logoWhatsapp } from 'ionicons/icons'
	import LayoutNovios from '../../../Layout/LayoutNovios.vue'

	export default {
		components: {
			IonPage, IonContent,IonIcon, IonFabButton, IonFab, IonList, IonItem, IonLabel, IonTitle, IonButtons, IonButton, IonToolbar, IonModal, IonSelect, IonSelectOption, IonInput,
			LayoutNovios
		},
        data() {
            return {
                dataUser: {},
				inviAdd: {
					Codigo: 0,
					Titulo: "",
					Nombre: "",
					Adultos: 0,
					Jovenes: 0,
					Ninos: 0,
					Tel: "",
					Invide: ""
				}
            }
        },
		setup() {
			const enterAnimation = (baseEl) => {
				const root = baseEl.shadowRoot;

				const backdropAnimation = createAnimation()
				.addElement(root.querySelector('ion-backdrop'))
				.fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

				const wrapperAnimation = createAnimation()
				.addElement(root.querySelector('.modal-wrapper'))
				.keyframes([
					{ offset: 0, opacity: '0', transform: 'scale(0)' },
					{ offset: 1, opacity: '0.99', transform: 'scale(1)' },
				]);

				return createAnimation()
				.addElement(baseEl)
				.easing('ease-out')
				.duration(500)
				.addAnimation([backdropAnimation, wrapperAnimation]);
			};

			const leaveAnimation = (baseEl) => {
				return enterAnimation(baseEl).direction('reverse');
			};
			return{
				add, create, trash, shareSocial, logoWhatsapp, enterAnimation, leaveAnimation
				
			}
		},
		methods: {
			/* Compartir enlace */
			shareData(code) {
				navigator.share({
					//title: 'Compartir enlaces',
					url: `${this.dataUser.urlInvi}?i=${code}`
				})
			},
			/* Fin Compartir enlace */

			/* Cargar la lista */
			async getList() {
				await axios.get(`${URL}/lista/${this.dataUser.id_list}`, {headers:{token: this.dataUser.token}})
				.then((res) => {
					this.dataUser.lista = res.data.lista
				})
				.catch((error) => {
					console.log(error)
					this.presentToast("danger", error.response.data.message)
				})
			},
			/* Fin Cargar la lista */

			/* Guardar la lista en BD */
			async saveList() {
				await axios.put(`${URL}/lista/${this.dataUser.id_list}`, {
						"lista": this.dataUser.lista
					}, {headers:{token: this.dataUser.token}})
					.then(() => {
						localStorage.setItem("DataUser", JSON.stringify(this.dataUser));
						this.presentToast("success", "Lista actualizada corectamente")
						this.dismiss()
						this.$router.go()
					})
					.catch((error) => {
						console.log(error)
						this.presentToast("danger", error.response.data.message)
					})
			},
			/* Fin Guardar la lista en BD */

			/* Crear una lista Nueva */
			async createList() {
				await axios.post(`${URL}/lista`, {
					"userId": this.dataUser._id,
					"lista": []
				}, {headers:{token: this.dataUser.token}})
				.then((res) => {
					this.dataUser.id_list = res.data._id
					this.dataUser.lista = []
					axios.put(`${URL}/user/${this.dataUser._id}`, {id_list: res.data._id}, {headers:{token: this.dataUser.token}}).then(() => {
						localStorage.setItem("DataUser", JSON.stringify(this.dataUser));
						this.presentToast("success", "Lista Creada")
					})
				})
				.catch((error) => {
					console.log(error)
					/* this.presentToast("danger", error.response.data.message) */
				})
			},
			/* Fin Crear una lista Nueva */

			async editInvi(a) {
				this.inviAdd = this.dataUser.lista[a]
				await this.$refs.modal.$el.present();
			},

			/* Agregar un invitado a la lista */
			addInvi() {
				if (this.inviAdd.Titulo != "" && this.inviAdd.Nombre != "") {
					if (this.inviAdd.Adultos || this.inviAdd.Jovenes || this.inviAdd.Ninos) {
						this.inviAdd.Adultos = parseInt(this.inviAdd.Adultos)
						this.inviAdd.Jovenes = parseInt(this.inviAdd.Jovenes)
						this.inviAdd.Ninos = parseInt(this.inviAdd.Ninos)

						if (this.dataUser.lista == undefined) {
							this.dataUser.lista = []
						}
						if (!this.inviAdd.Codigo) {
							this.inviAdd.Codigo = this.newCode()
							this.dataUser.lista.push(this.inviAdd)
						}else {
							this.dataUser.lista = this.dataUser.lista.map(item => {
								if (item.Codigo === this.inviAdd.Codigo) {
									return this.inviAdd;
								}
								return item;
							});
						}

						this.saveList()
					} else {
						this.presentToast("warning", "Debes incluir al menos un cupo.")
					}
				} else {
					this.presentToast("warning", "Titulo y Nombres son obligatorios")
				}
			},
			newCode () {
				// Generamos un número aleatorio entre 1000 y 9999
				let codigo = Math.floor(Math.random() * (9999 - 1000 + 1) + 1000);
				// Verificamos si el código se repite en la lista
				if(this.dataUser.lista){
					while (this.dataUser.lista.some(item => item.Codigo === codigo)) {
						codigo = Math.floor(Math.random() * (9999 - 1000 + 1) + 1000);
					}
				}
				return codigo;
			},
			/* Fin Agregar un invitado a la lista */

			/* Obtener el largo de un dato */
			lengthItem(item) {
				return item.length
			},
			/* Fin Obtener el largo de un dato */

			/* limpiar el nuevo invitado */
			dismiss(a) {
				this.$refs.modal.$el.dismiss();
				this.inviAdd = {
					Codigo: "",
					Titulo: "",
					Nombre: "",
					Adultos: null,
					Jovenes: null,
					Ninos: null,
					Tel: "",
					Invide: ""
				}
				if (a == 1) {
					this.$router.go()
				}
			},
			/* Fin limpiar el nuevo invitado */

			/* Mostrar Alertas */
			async showAlert(index) {
				const alert = await alertController.create({
					header: '¿Estás seguro',
					message: "que quieres eliminar el invitado de la lista?",
					buttons: [
						{
							text: 'Cancelar',
							role: 'cancel',
							handler: () => {
								this.$router.go()
							}
						},
						{
							text: 'Eliminar',
							role: 'confirm',
							cssClass: 'danger',
							handler: () => {
								this.dataUser.lista.splice(index, 1);
								this.saveList()
							},
						},
					],
				});
				await alert.present();
			},

			async presentToast(c, msg) {
				const toast = await toastController.create({
					message: msg,
					color: c,
					duration: 1500,
				});
				await toast.present();
			},

			toWhatsapp(num, code) {
				window.open(`https://api.whatsapp.com/send/?phone=${num}&text=${this.dataUser.urlInvi}?i=${code}`, '_blank')
			}
			/* Fin Mostrar Alertas */
		},
		watch: {
			'$router': {
				handler(){
					if (localStorage.getItem('DataUser')) {
						this.dataUser = JSON.parse(localStorage.getItem('DataUser'))
						if (this.dataUser.id_list) {
							this.getList()
						}
					}
					document.title = 'Lista de Invitados - Novias Diez'
				},
				immediate: true
			}
		}
	}
</script>