import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import LoginPage from '../component/LoginComponent/LoginPage.vue'
import RegisterPage from '../component/LoginComponent/RegisterPage.vue'
import AccountRecovery from '../component/LoginComponent/AccountRecovery.vue'
import ResetPass from '../component/LoginComponent/ResetPass.vue'

import DashboardPage from '../views/DashboardPage.vue'

import DashboardNovios from '../component/DashboardComponent/DashboardNovios.vue'
import HomeNovios from '../component/DashboardComponent/NoviosComponent/HomeNovios.vue'
import ListaNovios from '../component/DashboardComponent/NoviosComponent/ListaNovios.vue'

const routes: Array<RouteRecordRaw> = [
	// Inicio Login
	{
	path: '/',
	redirect: '/login'
	},
	{
	path: '/login',
	name: 'Login',
	component: LoginPage
	},
	{
	path: '/register',
	name: 'Register',
	component: RegisterPage
	},
	{
	path: '/accountrecovery',
	name: 'AccountRecovery',
	component: AccountRecovery
	},
	{
	path: '/resetpass',
	redirect: '/accountrecovery'
	},
	{
	path: '/resetpass/:id',
	name: 'ResetPass',
	component: ResetPass
	},
	// Fin Login

	{
		path: '/dashboard',
		name: 'DashboardPage',
		component: DashboardPage
	},
	
	// Inicio Usuario Novios
	{
		path: '/dashboardnovios',
		component: DashboardNovios,
		children: [
			{
				path: '',
				name: 'HomeNovios',
				component: HomeNovios
			},
			{
				path: 'lista',
				name: 'ListaNovios',
				component: ListaNovios
			}
		]
	}
	// Inicio Usuario Novios
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router