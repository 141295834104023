<template>
	<ion-page>
		<ion-content>
			<div class="p-2 h-100 d-flex flex-column ai-center jc-center">
				<img src="@/../public/assets/img/logo-vertical.svg" alt="Logo" class="w-100 max-w-150 mb-2" />
				<form @submit.prevent="submitForm" class="w-100 max-w-300">
					<ion-item>
						<ion-label position="floating">Correo</ion-label>
						<ion-input v-model="email" type="email"></ion-input>
					</ion-item>
					<div class="min-h-15 my-0-25">
						<p class="text-danger text-center h5">{{ msgError }}</p>
						<p class="text-success text-center h5">{{ msgSend }}</p>
					</div>
					<ion-button type="submit" expand="block">Siguiente</ion-button>
				</form>
				<div class="ion-text-center ion-margin-top">
					<p class="h5">¿Ya tienes cuenta? <span @click="$router.replace('/Login')" class="h5 text-link cursor-p">Iniciar Sesion</span></p>
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
	const isEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
	const URL = "https://backend-noviasdiez.up.railway.app/api"

	import axios from 'axios';
	import {IonContent, IonPage, IonItem, IonLabel, IonInput, IonButton} from '@ionic/vue'

	export default {
		components: {
			IonContent, IonPage, IonItem, IonLabel, IonInput, IonButton
		},
		data() {
			return {
				email: '',
				msgError: '',
				msgSend: ''
			}
		},
		methods: {
			async submitForm() {
				this.msgError = '';
				this.msgSend = ''

				// Validate form data
				if (!this.email) {
					this.msgError = 'Por favor ingresa un correo';
					return;
				}

				if (!isEmail.test(this.email)) {
					this.msgError = 'Por favor ingresa un correo válido';
					return;
				}

				// Send form data to server
				await axios.post(`${URL}/forgotpass`, {
						email: this.email
					})
					.then((res) => {
						/* despues de registrar Inicia Sesion */
						this.msgSend = res.data.message
						this.email = ''
					})
					.catch((error) => {
						/* Si tenemos un error */
						this.msgError = error.response.data.message;
					})
			}
		},
		watch: {
			'$router': {
				handler(){
					if (localStorage.getItem('DataUser') != null) {
                        this.$router.replace('/dashboard')
                    } else {
						document.title = 'Recuperacion de Cuenta - Novias Diez'
                    }
				},
				immediate: true
			}
		}
	}
</script>