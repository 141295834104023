<template>
	<ion-page>
		<ion-content>
			<div class="p-2 h-100 d-flex flex-column ai-center jc-center">
				<img src="@/../public/assets/img/logo-vertical.svg" alt="Logo" class="w-100 max-w-150 mb-2" />
				<form @submit.prevent="login" class="w-100 max-w-300">
					<ion-item>
						<ion-label position="floating">Correo</ion-label>
						<ion-input v-model="email" type="email"></ion-input>
					</ion-item>
					<ion-item>
						<ion-label position="floating">Contraseña</ion-label>
						<ion-input v-model="pass" type="password" @keyup.enter="login()"></ion-input>
					</ion-item>
					<div class="min-h-15 my-0-25"><p class="text-danger text-center h5" >{{ msgError }}</p></div>
					<ion-button type="submit" expand="block" class="bg-primary" @click="login()">Iniciar Sesion</ion-button>
				</form>
				<div class="ion-text-center ion-margin-top">
					<p class="h5 cursor-p" @click="$router.replace('/accountrecovery')"><span class="h5 text-link">¿Has olvidado tu contraseña?</span></p>
				</div>
				<div class="ion-text-center ion-margin-top">
					<p class="h5">¿No tienes cuenta? <span @click="$router.replace('/register')" class="h5 text-link cursor-p">Registrate</span></p>
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
	const isEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
	const URL = "https://backend-noviasdiez.up.railway.app/api"

	import axios from 'axios'
	import { IonContent, IonPage, IonItem, IonLabel, IonInput, IonButton } from '@ionic/vue'
	export default {
		components: {
			IonContent, IonPage, IonItem, IonLabel, IonInput, IonButton
		},
		data() {
			return {
				msgError: '',
				email: '',
				pass: ''
			}
		},
		methods: {
			async login() {
				this.msgError = ''
				if(!this.email || !this.pass){
					this.msgError = 'Todos los campos son requeridos'
				}else if(!isEmail.test(this.email)){
					this.msgError = 'Por favor ingresa un correo válido'
				}else {
					axios.post(`${URL}/login`, {email: this.email, pass: this.pass})
					.then((res) => {
						localStorage.setItem("DataUser", JSON.stringify(res.data));
						if(this.$router.currentRoute._value.path == '/login'){
							this.$router.replace('/dashboard')
						}else{
							this.$router.go();
						}
					})
					.catch((error) => {
						console.log(error)
							this.msgError = error.response.data.message
						})
				}
			}
		},
		watch: {
			'$router': {
				handler(){
					if (localStorage.getItem('DataUser') != null) {
                        this.$router.replace('/dashboard')
                    } else {
						document.title = 'Inicio de Sesion - Novias Diez'
                    }
				},
				immediate: true
			}
		}
	}
</script>

<style scoped>
</style>