<template>
	<ion-page>
		<ion-content>
			<div v-if="dataUser.Rol == 'editor'">Dashboard Editor</div>
			<div v-if="dataUser.Rol == 'admin'">Dashboard Admin</div>
			<div v-if="dataUser.Rol == 'superadmin'">Dashboard Super Admin</div>
		</ion-content>
	</ion-page>
</template>

<script>
	import {IonContent, IonPage} from '@ionic/vue'
	export default {
		components: {
			IonContent, IonPage
		},
		data() {
			return {
				dataUser: {}
			}
		},
		mounted() {
			if (JSON.parse(localStorage.getItem('DataUser')).rol == 'novios') {
				this.$router.replace('/dashboardnovios')
			}
		},
	}
</script>