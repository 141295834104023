<template>
	<ion-page>
		<ion-content>
			<layout-novios>
				<div class="p-2 h-100 d-flex flex-column ai-center jc-center">
					<p>Hola</p>
					<h1>{{ dataUser.name }}</h1>
					<IonButton href="/dashboardnovios/lista">Lista de Invitados</IonButton>
				</div>
			</layout-novios>
		</ion-content>
	</ion-page>
</template>

<script>
	/* import axios from 'axios' */
	import { IonButton, IonPage, IonContent } from '@ionic/vue'
	import LayoutNovios from '../../../Layout/LayoutNovios.vue'
	export default {
		components: {
			IonButton, IonPage, IonContent,
			LayoutNovios
		},
		data() {
			return {
                dataUser: {name: ""}
			}
		},
		methods: {
		},
		watch: {
			'$router': {
				handler(){
					this.dataUser = JSON.parse(localStorage.getItem('DataUser'))
					document.title = 'Dashboard - Novias Diez'
				},
				immediate: true
			}
		}
	}
</script>