<template>
	<ion-page>
		<ion-content>
			<div class="p-2 h-100 d-flex flex-column ai-center jc-center">
				<img src="@/../public/assets/img/logo-vertical.svg" alt="Logo" class="w-100 max-w-150 mb-2" />
				<form @submit.prevent="submitForm" class="w-100 max-w-300">
					<ion-item>
						<ion-label position="floating">Nueva Contraseña</ion-label>
						<ion-input v-model="pass" type="password"></ion-input>
					</ion-item>
					<ion-item>
						<ion-label position="floating">Confirmar contraseña</ion-label>
						<ion-input v-model="passConfir" type="password"></ion-input>
					</ion-item>
					<div class="min-h-15 my-0-25">
                        <p class="text-danger text-center h5" >{{ msgError }}</p>
                        <p class="text-success text-center h5" >{{ msgSend }}</p>
                    </div>
					<ion-button type="submit" expand="block">Continuar</ion-button>
				</form>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
	const URL = "https://backend-noviasdiez.up.railway.app/api"

	import axios from 'axios';
	import { IonContent, IonPage, IonItem, IonLabel, IonInput, IonButton } from '@ionic/vue'

	export default {
		components: {
			IonContent, IonPage, IonItem, IonLabel, IonInput, IonButton
		},
		data() {
			return {
				token: '',
				pass: '',
				passConfir: '',
				msgError: '',
                msgSend: ''
			}
		},
		methods: {
            async submitForm() {
				this.msgError = '';
                this.msgSend = ''
				
				if (!this.pass) {
					this.msgError = 'Por favor ingresa una contraseña';
					return;
				}
				if (!this.pass.match(/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/)) {
					this.msgError = 'La contraseña debe tener al menos 8 dígitos y contener al menos una mayúscula y un número';
					return;
				}
				if (this.pass !== this.passConfir) {
					this.msgError = 'Las contraseñas no coinciden';
					return;
				}

				// Envio de la info al servidor
				await axios.post(`${URL}/resetpass`, {pass: this.pass, token: this.token})
					.then((res) => {
                        this.msgSend = res.data.message
                        setTimeout(() => {
                            this.$router.replace('/login')
                        }, 1000);
					})
					.catch((error) => {
						this.msgError = error.response.data.message;
					})
			}
		},
		watch: {
			'$router': {
				handler(){
					if (localStorage.getItem('DataUser') != null) {
                        this.$router.replace('/dashboard')
                    } else {
						this.token = this.$route.params.id
						document.title = 'Nueva Contraseña - Novias Diez'
                    }
				},
				immediate: true
			}
		}
	}
</script>