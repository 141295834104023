<template>
	<ion-menu content-id="main-content">
		<ion-header>
			<ion-toolbar class="px-2">
				<ion-title class="text-center">
					<img src="../../public/assets/img/logo-novias-diez-horizontal.svg" alt="Logo Novias Diez" class="max-w-100">
				</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content class="ion-padding">
			<ion-list>
				<ion-item  href="/dashboardnovios">
					<ion-icon :icon="home" class="mr-1" /> Inicio
				</ion-item>
				<ion-item href="/dashboardnovios/lista">
					<ion-icon :icon="list" class="mr-1" /> Lista de Invitados
				</ion-item>
			</ion-list>
		</ion-content>
	</ion-menu>
	<ion-page id="main-content">
		<ion-header>
			<ion-toolbar class="d-flex">
				<ion-buttons slot="start">
					<ion-menu-button class="h1"></ion-menu-button>
				</ion-buttons>
				<!-- <ion-title>Menu</ion-title> -->
				<ion-title class="text-center">
					<img src="../../public/assets/img/logo-novias-diez-horizontal.svg" alt="Logo Novias Diez" class="max-w-100" />
				</ion-title>
				<ion-buttons slot="end">
					<ion-button  id="click-trigger" class="min-h-50">
						<ion-avatar class="square-40">
							<img src="../../public/assets/img/avatardefault.svg" alt="Avatar" v-if="dataUser.avatar == null">
							<img :src="dataUser.avatar" alt="Avatar" v-else/>
						</ion-avatar>
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content>
			<slot></slot>
		</ion-content>

		<ion-popover trigger="click-trigger" trigger-action="click" side="bottom" alignment="end">
			<ion-list>
				<!-- <ion-item @click="$router.go('/')">Configuración</ion-item>
				<ion-item @click="$router.go('/')">Perfil</ion-item> -->
				<ion-item @click="cerrarSesion()">Cerrar sesión</ion-item>
			</ion-list>
		</ion-popover>

	</ion-page>
</template>

<script>
	import {IonMenu, IonHeader, IonToolbar, IonContent, IonMenuButton, IonTitle, IonPage, IonButtons, IonButton, IonAvatar, IonPopover, IonItem, IonList, IonIcon } from '@ionic/vue'
	import { home, list } from 'ionicons/icons';
	export default {
		components: {IonMenu, IonHeader, IonToolbar, IonContent, IonMenuButton, IonTitle, IonPage, IonButtons, IonButton, IonAvatar, IonPopover, IonItem, IonList, IonIcon
		},
		data() {
			return {
				dataUser: {}
			}
		},
		setup() {
			return{
				home, list
			}
		},
		methods: {
			cerrarSesion() {
				localStorage.removeItem('DataUser')
				window.location.replace('login')
			}
		},
		watch: {
			'$router': {
				handler() {
					if (localStorage.getItem('DataUser') == null) {
						this.$router.replace('/login')
					} else {
						this.dataUser = JSON.parse(localStorage.getItem('DataUser'))
					}
				},
				immediate: true
			}
		}
	}
</script>